const fonts = [
  {
    name: 'wotfardregular',
    url: '/fonts/wotfard-regular-webfont.woff2',
    style: 'normal',
    weight: 'normal'
  },
  {
    name: 'wotfardsemibold',
    url: '/fonts/wotfard-semibold-webfont.woff2',
    style: 'normal',
    weight: 600
  },
  {
    name: 'wotfardmedium',
    url: '/fonts/wotfard-medium-webfont.woff2',
    style: 'normal',
    weight: 600
  },
  {
    name: 'CHANEY',
    url: '/fonts/chaney-regular-webfont.woff2',
    style: 'normal',
    weight: 'normal'
  },
  {
    name: 'wotfardbold',
    url: '/fonts/Wotfard-Bold.otf',
    style: 'normal',
    weight: 'bold'
  },
  {
    name: 'NewScienceBold',
    url: '/fonts/New_Science_Bold.otf',
    style: 'normal',
    weight: 'bold'
  },
  {
    name: 'NewScienceRegular',
    url: '/fonts/New_Science_Regular.otf',
    style: 'normal',
    weight: 'normal'
  }
];

async function loadFonts() {
  console.log('loadFonts: loading fonts');
  const result = await Promise.allSettled(
    fonts.map(async (item) => {
      const { style, weight, name, url } = item;
      const font = new FontFace(name, `url(${url})`, { style, weight });
      await font.load();
      return name;
    })
  );
  console.log('loadFonts: result ', result);
}

export { loadFonts };
