import { getUserToken } from '../../components/firebase/firebase';
import { liveServerName, loadMeetingLibrary } from '../../constants/Url';
import Request from '../../Services/Request';
import { isDesktopApp } from '../../utils/platformUtils';
import { updatePlatformError } from '../device/action';
import { startQuickSession } from '../mixl/action';
import { RootState } from '../root-reducer';
import { generateQuizQuestions, getStudyNotesLinks, getStudyRoomName } from './functions';
import { INoteData, studyActionTypes } from './types';

const { PostSecuredData } = Request();

export const startAnalysingNotes = () => (dispatch) => {
  dispatch({
    type: studyActionTypes.START_PROCESSING_NOTES
  });
};

export const stopAnalysingNotes = () => (dispatch) => {
  dispatch({
    type: studyActionTypes.STOP_PROCESSING_NOTES
  });
};

export const processStudyNotes = (studyNotes: INoteData[], startSession: () => void) => async (dispatch, getState) => {
  const store = getState() as RootState;
  const {disableExperimentalFeature} = store.web3NetworkData
  if (studyNotes?.length > 0) {
    let didStartSession = false;
    for await (const noteData of studyNotes) {
      const quizData = await generateQuizQuestions(noteData, !disableExperimentalFeature);
      if (quizData?.length > 0) {
        dispatch({ type: studyActionTypes.ADD_QUIZ_DATA, quizData, noteData });
        if (!didStartSession) {
          didStartSession = true;
          const { sessionStarted } = store.mixlData;
          if (!sessionStarted) {
            await dispatch(startQuickSession('Quiz', 'quiz'));
          }
          startSession();
        }
      }
    }
  }
  
  const quizData = getState().studyData.quizData;
  if (!quizData?.length) {
    console.log('err: no quiz data available');
    dispatch(updatePlatformError('Something went wrong. Please try again later'));
  }

  dispatch(stopAnalysingNotes());
};

export const updateQuizAnswer = (answer: string, quizId: string) => (dispatch, getState) => {
  const store = getState() as RootState;
  const newQuizData = [...store.studyData.quizData];
  const quiz = newQuizData.find((item) => item.id === quizId);
  if (quiz) {
    quiz['selectedAnswer'] = answer;
  }
  dispatch({ type: studyActionTypes.UPDATE_QUIZ_DATA, quizData: [...newQuizData] });
};

export const resetStudySession = () => (dispatch) => {
  dispatch({
    type: studyActionTypes.RESET_SESSION_DATA
  });
};

export const isLoadingStudyRoom = (isLoading) => {
  return { type: studyActionTypes.UPDATE_LOADING_ROOM, isLoading };
};

export const initialseStudyRoom = () => (dispatch, getState) => {
  const parentNode = document.getElementById('meeting_container');
  if (!parentNode) {
    return;
  }
  const roomName = getStudyRoomName(getState);
  if (!roomName) {
    return;
  }
  const JitsiMeetExternalAPI = window.JitsiMeetExternalAPI;
  // prettier-ignore
  const mixlLiveApi = new JitsiMeetExternalAPI(liveServerName, {
    width: '100%', height: '100%', roomName, parentNode, configOverwrite: {
      prejoinConfig: { enabled: false }, disableModeratorIndicator: true, hideDisplayName: true,
      hideConferenceSubject: true, toolbarButtons: [], notifications: [], remoteVideoMenu: {
        disablePrivateChat: true, disableDemote: true, disableGrantModerator: true,
      }, defaultRemoteDisplayName: 'Mixlearn User', enableInsecureRoomNameWarning: false,
      hideRecordingLabel: true, filmstrip: { disableResizable: true }, participantsPane: {
        hideModeratorSettingsTab: true, hideMoreActionsButton: true, hideMuteAllButton: true
      }, deeplinking: { disabled: true }, disableLocalVideoFlip: true, disableRemoteMute: true,
      enableNoAudioDetection: false, analytics: { disabled: true },
      maxFullResolutionParticipants: -1, enableNoisyMicDetection: false, legalUrls: {
        privacy: 'https://app.mixl.ai/privacypolicy', terms: 'https://app.mixl.ai/termsofservice',
        helpCentre: 'https://www.mixl.ai/#community'
      }, dynamicBrandingUrl: 'https://app.mixl.ai/orm/community/live_config'
    }, interfaceConfigOverwrite: {
      SUPPORT_URL: 'https://www.mixl.ai/#community', DEFAULT_REMOTE_DISPLAY_NAME: 'Mixlearn User',
      PROVIDER_NAME: 'Mixl', SHOW_JITSI_WATERMARK: false, DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
      MOBILE_APP_PROMO: false, AUTO_PIN_LATEST_SCREEN_SHARE: true, DISABLE_VIDEO_BACKGROUND: true,
      APP_NAME: 'Mixl Learn', VIDEO_QUALITY_LABEL_DISABLED: true, VERTICAL_FILMSTRIP: false,
    }
  });

  return mixlLiveApi;
};

export const maybeInviteAdmin = () => (dispatch, getState) => {
  const roomName = getStudyRoomName(getState);
  const studyNotes = getStudyNotesLinks(getState);

  getUserToken().then((token) => {
    if (token) {
      PostSecuredData('community/live_invite', { roomName, studyNotes }, `Bearer ${token}`).catch((err) => {
        console.log('Err: unable to send invite', err);
      });
    }
  });
};

export const startLiveSession = () => async (dispatch) => {
  dispatch(isLoadingStudyRoom(true));
  const meetingModule = await loadMeetingLibrary();
  if (!meetingModule) {
    dispatch(updatePlatformError('Unable to start session'));
    dispatch(isLoadingStudyRoom(false));
    return;
  }
  // const roomName = `mixl_live_${Date.now()}`;
  const roomName = `mixl_live_tutor`;
  const roomInfo = { roomName };
  if (isDesktopApp) {
    window.CapacitorCustomPlatform.startLiveSession();
  }
  dispatch({ type: studyActionTypes.START_LIVE_SESSION, roomInfo });
};

export const stopLiveSession = () => (dispatch) => {
  if (isDesktopApp) {
    window.CapacitorCustomPlatform.stopLiveSession();
  }
  dispatch({ type: studyActionTypes.STOP_LIVE_SESSION });
};
