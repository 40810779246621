export const gloqalBusiness = {
  SHOW_TERMS_MODAL: 'SHOW_TERMS_MODAL',
  BRAND_NAME: 'BRAND_NAME',
  UPDATE_SLACK_AUTH: 'UPDATE_SLACK_AUTH',
  UPDATE_CLICKUP_AUTH: 'UPDATE_CLICKUP_AUTH',
  UPDATE_JIRA_AUTH: 'UPDATE_JIRA_AUTH',
  UPDATE_NOTION_AUTH: 'UPDATE_NOTION_AUTH',
  UPDATE_GCAL_AUTH: 'UPDATE_GCAL_AUTH',
  UPDATE_OURA_AUTH: 'UPDATE_OURA_AUTH',
  COMPANY_DETAILS: 'COMPANY_DETAILS',
  INIT_AUTH_STATE: 'INIT_AUTH_STATE',
  UPLOAD_PHOTO_MODAL: 'UPLOAD_PHOTO_MODAL',
  SET_FIRST_TIME_USER: 'SET_FIRST_TIME_USER',
  SHOW_DELETE_ACCOUNT_MODAL: 'SHOW_DELETE_ACCOUNT_MODAL',
  SET_ACTIVE_INDEX: 'SET_ACTIVE_INDEX',
  SET_HAS_PREMIUM_SUBSCRIPTION: 'SET_HAS_PREMIUM_SUBSCRIPTION',
  SET_SHOW_INVITE_MODAL: 'SET_SHOW_INVITE_MODAL',
  SET_SHOW_PROFILE: 'SET_SHOW_PROFILE'
};

export const defaultUserDetails = {
  account_type: '',
  avatar: '',
  email: '',
  emailVerified: false,
  isGloAdmin: false,
  isMixlLearnUse: false,
  phoneNumber: '',
  preference: {},
  server: '',
  stageImage: '',
  userName: '',
  welcomeVideo: '',
  referralId: '',
  createdAt: null,
  maxReferralLimit: 0,
  index: null,
  moodFlowWelcomeUnshow: false,
  followersCount: 0,
  resizedImages: null,
  location: null,
  referralImage: '',
  workingStartTime: '',
  workingEndTime: '',
  workTemplate: []
};
