import { getIdTokenResult } from 'firebase/auth';
import { AnyAction } from 'redux';

import { firebaseAuth } from '../components/firebase/firebase';
import { getWebsiteIcon } from '../Services/TaskService';
import { sleep } from '../utils/miscUtils';
import { isCapacitor, isExtenstionSidebar, platforInfo, postIframeMessage } from '../utils/platformUtils';
import { setOpenUploadPhotoModal } from './business/action';
import { gloqalBusiness } from './business/type';
import { shouldShowMobilePromo } from './device/action';
import { clearUnreadMessageCount, setBlockedList } from './mixl/action';
import { mixlActionTypes } from './mixl/types';
import { IStore } from './reduxTypes';
import { SHOW_AGENT_VIEW } from './style/type';

export const businessMiddleware = (store: IStore) => (next: () => void) => (action: AnyAction) => {
  const { dispatch, getState } = store;
  switch (action.type) {
    case gloqalBusiness.INIT_AUTH_STATE: {
      if (isExtenstionSidebar()) {
        sleep(1000).then(() => {
          postIframeMessage({ type: 'sidebar_init' });
        });
      }
      if (!isCapacitor && platforInfo.includes('mobileweb') && platforInfo.includes('ios')) {
        dispatch(shouldShowMobilePromo(true));
      }
      const currentUser = firebaseAuth.currentUser;
      if (!currentUser || !currentUser.email) {
        return next(action);
      }
      getIdTokenResult(currentUser, true).then((tokenResult) => {
        const dateTermsAccepted = tokenResult.claims?.dateTermsAccepted || 0;
        const termsDate = getState().businessData.termsDate;
        const currentTermsDate = new Date(termsDate).getTime();
        if (!dateTermsAccepted || dateTermsAccepted !== currentTermsDate) {
          const openUploadPhotoModal = getState().businessData.openUploadPhotoModal;
          if (!openUploadPhotoModal) {
            dispatch(setOpenUploadPhotoModal(true));
          }
        }
      });
      break;
    }
    case mixlActionTypes.SET_SELECTED_TASK_CLICKUP: {
      if (isExtenstionSidebar()) {
        const payload = action.payload;
        if (payload) {
          postIframeMessage({ type: 'update_task_data', payload });
        }
      }
      break;
    }
    case SHOW_AGENT_VIEW: {
      const isVisible = action.isVisible;
      if (isVisible) {
        dispatch(clearUnreadMessageCount());
      }
      break;
    }
    case mixlActionTypes.SHOW_TASK_DETAILS_MODAL: {
      const payload = action?.payload;
      console.log('SHOW_TASK_DETAILS_MODAL payload ', payload);
      if (payload?.sites?.length) {
        const sitesData = payload?.sites;
        Promise.all(
          sitesData.map((item) => {
            if (!item?.icon) {
              const icon = getWebsiteIcon(item?.Website);
              item.icon = icon;
            }
            return item;
          })
        ).then((result) => {
          dispatch(setBlockedList(result));
        });
      }

      break;
    }
  }
  return next(action);
};
