import { IStudyData, studyActionTypes } from './types';

const defaultStudyData = {
  analysingNotes: false,
  quizData: [],
  studyNotes: [],
  resultData: {
    correctAnswersPercentage: 0,
    incorrectAnswersPercentage: 100,
    correctAnswersCount: 0
  }
};
const initialState: IStudyData = {
  roomInfo: null,
  loadStudyRoom: false,
  ...defaultStudyData
};

export const studyReducer = (state: IStudyData = initialState, action): IStudyData => {
  switch (action.type) {
    case studyActionTypes.UPDATE_LOADING_ROOM: {
      return { ...state, loadStudyRoom: action.isLoading };
    }
    case studyActionTypes.START_LIVE_SESSION: {
      return { ...state, roomInfo: action.roomInfo };
    }
    case studyActionTypes.STOP_LIVE_SESSION: {
      return { ...state, roomInfo: null };
    }
    case studyActionTypes.ADD_QUIZ_DATA: {
      const oldQuizData = [...state.quizData];
      const oldStudyNotes = [...state.studyNotes];

      return {
        ...state,
        quizData: [...oldQuizData, ...action.quizData],
        studyNotes: [...oldStudyNotes, action.noteData]
      };
    }
    case studyActionTypes.UPDATE_QUIZ_DATA: {
      return { ...state, quizData: [...action.quizData] };
    }
    case studyActionTypes.START_PROCESSING_NOTES: {
      return { ...state, analysingNotes: true };
    }
    case studyActionTypes.STOP_PROCESSING_NOTES: {
      return { ...state, analysingNotes: false };
    }
    case studyActionTypes.RESET_SESSION_DATA: {
      return { ...state, ...defaultStudyData };
    }
    default:
      return state;
  }
};
