import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { v4 as generateId } from 'uuid';

import { firebaseAuth, firebaseStorage } from '../../components/firebase/firebase';
import Request from '../../Services/Request';
import { RootState } from '../root-reducer';
import { INoteData } from './types';

const { PostDataCustomUrl } = Request();

export async function uploadStudyNote(file: File) {
  const uid = firebaseAuth.currentUser?.uid;
  if (!uid) {
    throw new Error('invalid-user');
  }
  const filePath = `/study_session/${uid}/${file.name}`;
  const storageRef = ref(firebaseStorage, filePath);
  const snapshot = await uploadBytes(storageRef, file);
  const downloadRef = snapshot.ref;
  const file_url = await getDownloadURL(downloadRef);
  return file_url;
}

export async function generateQuizQuestions(noteData: INoteData, isExperimental: Boolean) {
  const { urlLink, noteId } = noteData;
  if (import.meta.env.DEV) {
    const { mockQuizData } = await import('../../constants/mockData');
    return [...mockQuizData].map((item) => {
      if (!item) {
        return null;
      }
      item['noteId'] = noteId;
      item['id'] = generateId();
      item['selectedAnswer'] = '';
      return item;
    });
  }
  try {
    const { quiz } = await PostDataCustomUrl(
      `${isExperimental ? 'https://app.mixl.ai/mixl_agent/generate_quiz' : 'https://app.mixl.ai/task_estimate/generate_quiz'}`,
      {
        file_url: urlLink
      }
    );
    if (!quiz || !quiz?.length > 0) {
      throw new Error('no-quiz-found');
    }
    const data = quiz
      .map((item) => {
        if (!item) {
          return null;
        }
        item['id'] = generateId();
        item['selectedAnswer'] = '';
        return item;
      })
      .filter((item) => item !== null && item !== undefined);

    return data;
  } catch (error) {
    console.log('Err: unable to process link ', noteData, error.message);
    return [];
  }
}

export function getStudyRoomName(getState: () => RootState) {
  const store = getState();
  const roomName = store.studyData.roomInfo?.roomName;
  return roomName;
}
export function getStudyNotesLinks(getState: () => RootState) {
  const store = getState();
  const studyNotes = store.studyData.studyNotes;
  return studyNotes;
}
