export const studyActionTypes = {
  UPDATE_LOADING_ROOM: 'UPDATE_LOADING_ROOM',
  START_LIVE_SESSION: 'START_LIVE_SESSION',
  STOP_LIVE_SESSION: 'STOP_LIVE_SESSION',
  START_PROCESSING_NOTES: 'START_PROCESSING_NOTES',
  STOP_PROCESSING_NOTES: 'STOP_PROCESSING_NOTES',
  RESET_SESSION_DATA: 'RESET_SESSION_DATA',
  ADD_QUIZ_DATA: 'ADD_QUIZ_DATA',
  UPDATE_QUIZ_DATA: 'UPDATE_QUIZ_DATA'
};

export type INoteData = {
  title: string;
  urlLink: string;
  noteId: string;
};

export type IStudyData = {
  roomInfo: { roomName: string } | null;
  loadStudyRoom: boolean;
  studyNotes: Array<INoteData>;
  analysingNotes: boolean;
  quizData: never[];
  resultData: {
    correctAnswersPercentage: number;
    incorrectAnswersPercentage: number;
    correctAnswersCount: number;
  };
};
